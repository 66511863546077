import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "layout/Hero"
import Container from "layout/Container"
import { Brand } from "elements/Brand"
import Media from "elements/Media"

const WhatCanIExpect = () => {
  const data = useStaticQuery(graphql`
    {
      pen: file(relativePath: { eq: "icons/pen__red.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      heart: file(relativePath: { eq: "icons/heart__red.png" }) {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const pen = data.pen.childImageSharp.fixed
  const heart = data.heart.childImageSharp.fixed

  return (
    <Hero size="small">
      <Container isCentered>
        <h3 className="title has-text-centered">
          What can I expect from the program?{" "}
        </h3>

        <Media
          image={{
            src: pen.src,
            alt: "Pre-filled Syringe",
          }}
          title={
            <span>
              Experience <Brand />
            </span>
          }
        >
          The program will run for three months, in which patients will directly
          receive <Brand /> at their doorsteps. This will include dosing for
          three months, which includes a 0.25mg pen for the starting dose (4
          weeks) and two 0.5mg pens for the patient’s maintenance dose (8
          weeks).
        </Media>

        <Media
          image={{
            src: heart.src,
            alt: "Heart",
          }}
          title="You are not alone"
        >
          Patients will have access to educational resources around <Brand />{" "}
          and also have access to a direct hotline to nurse educators as part of
          the EDUCARE patient support program, who will accompany patients
          throughout their journey. Nurse educators will be available to educate
          patients around the disease, how to use the product, and other queries
          the patient may have.
        </Media>
      </Container>
    </Hero>
  )
}

export default WhatCanIExpect
