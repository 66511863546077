import React, { useEffect, Fragment } from "react"
import { useStaticQuery, navigate, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"

import Container from "layout/Container"
import { Brand, ProgramName } from "elements/Brand"

import { hasDoctorRole } from "auth/services/authUser"
import styles from "layout/layout.module.scss"

const HomeHero = () => {
  const data = useStaticQuery(graphql`
    {
      banner: file(
        relativePath: { eq: "novo_nordisk/banner-image__transparent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const banner = data.banner.childImageSharp.fluid

  useEffect(() => {
    if (hasDoctorRole()?.active) navigate("/doctor")
  }, [])

  return (
    <Fragment>
      <section className="hero">
        <div className="hero-body">
          <Container
            desktop={10}
            fullhd={10}
            customClassName="p-0-mobile"
            isCentered
          >
            <div className="columns">
              <div
                className={classNames("column", styles["bannerImageContainer"])}
              >
                <Img
                  fluid={banner}
                  alt="Ozempic Early Experience Program"
                  className="p-0"
                />
              </div>
              <div className="column ">
                {" "}
                <h2 className="mt-0-mobile">
                  The <Brand /> <br /> Early Experience Program
                </h2>
                <h4 className="subtitle mb-4">
                  The <ProgramName /> gives patients early access to <Brand />.
                  Through the program, doctors can gain clinical experience with{" "}
                  <Brand /> in treating patients with type 2 diabetes.
                </h4>
                <Link
                  className={classNames(
                    "button is-primary is-medium",
                    styles["home__buttonIsFullwidth"]
                  )}
                  to="/patient"
                >
                  Enroll as Patient
                </Link>
                <div className="is-flex mt-2 is-align-items-center">
                  <span className="is-size-3 icon has-text-primary">
                    <FontAwesomeIcon icon={faUserMd} />
                  </span>
                  <span className="mx-1">
                    Improve your patients’ health.{" "}
                    <Link to="/doctor/sign-up" className="has-text-weight-bold">
                      Register as a partner doctor
                    </Link>
                    .
                  </span>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
    </Fragment>
  )
}

export default HomeHero
