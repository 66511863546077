import React from "react"

const Media = ({ image, title, children }) => {
  return (
    <article className="media my-2">
      {image && (
        <figure className="media-left">
          <p className="image is-64x64">
            <img src={image.src} alt={image.alt || " "} />
          </p>
        </figure>
      )}
      <div className="media-content">
        <div className="content">
          <h3 className="has-text-weight-bold has-text-primary p-0 pb-1 m-0">
            {title}
          </h3>
          <p>{children}</p>
        </div>
      </div>
    </article>
  )
}

export default Media
