import React from "react"

import Layout from "layout/Layout"
import SEO from "layout/SEO"

import HomeHero from "staticPages/Home/HomeHero"
import WhatCanIExpect from "components/StaticPages/Home/WhatCanIExpect"
import WhatIsSemaglutide from "components/StaticPages/Home/WhatIsSemaglutide"
import WhoCanUseSemaglutide from "components/StaticPages/Home/WhoCanUseSemaglutide"
import HelpCenterBanner from "components/StaticPages/HelpCenter/HelpCenterBanner"

export default () => {
  return (
    <Layout>
      <SEO title="Home" />
      <HomeHero />
      <WhatCanIExpect />
      <WhatIsSemaglutide />
      <WhoCanUseSemaglutide />
      <HelpCenterBanner />
    </Layout>
  )
}
