import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Hero from "layout/Hero"
import Button from "elements/Button"
import Container from "layout/Container"

const HelpCenterBanner = () => {
  const data = useStaticQuery(graphql`
    {
      stethoscope: file(relativePath: { eq: "stethoscope.jpg" }) {
        childImageSharp {
          fluid(
            duotone: { highlight: "#ffffff", shadow: "#da0812" }
            toFormat: PNG
            quality: 90
            maxWidth: 800
          ) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const stethoscope = data.stethoscope.childImageSharp.fluid.src

  return (
    <Hero
      background={{ image: stethoscope }}
      className="has-background-cereal-teal mt-1"
    >
      <Container isCentered>
        <h4 className="title mb-0">Have another question?</h4>
        <p>Get answers at our Help Center or contact us at <strong>0968 230 6545</strong>.</p>
        <div className="buttons is-narrow are-white mb-1 mt-2">
          <Button variant="outlined" onClick={() => navigate("/help-center")}>
            Visit Help Center
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/help-center#contact-us")}
          >
            Contact Us
          </Button>
        </div>
      </Container>
    </Hero>
  )
}

export default HelpCenterBanner
