import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Hero from "layout/Hero"
import Container from "layout/Container"
import { Brand } from "elements/Brand"

const WhatIsSemaglutide = () => {
  const data = useStaticQuery(graphql`
    {
      ozempicHand: file(relativePath: { eq: "novo_nordisk/ozempic-hand.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const ozempicHand = data.ozempicHand.childImageSharp.fluid

  return (
    <Hero color="light">
      <Container isCentered>
        <div className="columns">
          <div className="column">
            <Img fluid={ozempicHand} alt="Ozempic" />
          </div>
          <div className="column content">
            <h3 className="title">
              <Brand className="has-text-primary" /> is a once-weekly GLP-1 RA medication
              for type 2 diabetes. It is not an insulin.
            </h3>
            <ul className="is-size-5">
              <li>
                It helps your body reduce your blood sugar level when it is too
                high
              </li>
              <li><Brand /> helps reduce body weight by regulating appetite and satiety</li>
              <li>
                It is 94% similar to a natural hormone called GLP-1 that your body releases
                after eating
              </li>
            </ul>
            <small>Reference: <Brand /> PhFDA-Approved Full Prescribing Information © October 2018.</small>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default WhatIsSemaglutide
