import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

const Card = ({ children }) => {
  return (
    <div className={classNames("content is-size-5", styles["card"])}>
      <div className="card-content">{children}</div>
    </div>
  )
}

export default Card
