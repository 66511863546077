import React from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"
import Card from "elements/Card"

// import content from "../utils/whoCanUseSemaglutide.json"
import { ProgramName, Brand } from "../../Elements/Brand"

// const CardColumn = ({ title, children }) => {
//   return (
//     <div className="column">
//       <Card>
//         <h5 className="has-text-weight-bold">{title}</h5>
//         <div className="content">{children}</div>
//       </Card>
//     </div>
//   )
// }

const WhoCanUseSemaglutide = () => {
  return (
    <Hero>
      <Container isCentered desktop={10} fullhd={8}>
        {/* <div className="columns">
          {content.map((content, index) => (
            <CardColumn title={content.title} key={index}>
              <ul>
                {content.content.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
            </CardColumn>
          ))}
        </div> */}
        <h3 className="title has-text-centered">
          Who is eligible for <ProgramName />?
        </h3>
        <p className="help has-text-centered">
          Reference: <Brand /> PhFDA-Approved Full Prescribing Information ©
          October 2018.
        </p>
        <div className="columns mt-1">
          <div className="column">
            <Card>
              <h5 className="has-text-primary is-size-4 mt-0">
                Inclusion Criteria
              </h5>
              <ul>
                <li>
                  Any adult patient (18 years old and above) with type 2
                  diabetes (preferably GLP-1 and insulin naïve)
                </li>
                <li>
                  Has a valid prescription for <Brand /> from an enrolled doctor
                </li>
                <li>
                  Resides or is able to receive the medicines within our service
                  areas
                </li>
                <li>
                  In accordance with local approved indication{" "}
                  <p className="help">
                    You may request full prescribing information at{" "}
                    <a href="mailto:NNPPISafety@novonordisk.com">
                      NNPPISafety@novonordisk.com
                    </a>
                    .
                  </p>
                </li>
              </ul>
            </Card>
          </div>
          <div className="column">
            <Card>
              <h5 className="has-text-primary is-size-4 mt-0">
                Exclusion Criteria
              </h5>
              <ul>
                <li>
                  Persons with known hypersensitivity to <Brand /> or any of its
                  excipients
                </li>
              </ul>
              <br />
              <h5 className="mt-0">
                Persons with any of the following conditions:
              </h5>
              <ul>
                <li>Congestive Heart Failure NHYA Class IV</li>
                <li>Severe hepatic impairment (Child’s Pugh C)</li>
                <li>
                  Severe renal impairment (Chronic Kidney Disease stage 4 to 5)
                </li>
                <li>Inflammatory bowel disease and diabetic gastroparesis</li>
                <li>Pregnant and lactating women</li>
              </ul>
            </Card>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default WhoCanUseSemaglutide
